/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { paths } from '@/constants/paths'
import { useToast } from '@/molecules/Toast'
import { getWebClient } from '@/services/ApolloClient'
import { useGuildMember, useUserGuildTickets } from '@/services/GuildUserService'
import { TheatricalPromoType, TicketPromoTypes } from '@/services/Theatrical/contentfulQueries'
import { isTheatricalPromoValid } from '@/services/Theatrical/utils'
import { TheatricalPromoExperimentVariation } from '@/types/codegen-contentful'
import {
  ReservationObject,
  TheatricalReleaseObject,
  TheatricalShowtime,
  TheatricalShowtimeVenue,
} from '@/types/codegen-federation'
import { ReactFCC } from '@/types/react'
import { useLocale } from '@/utils/LocaleUtil'
import { useSafeAnalytics } from '@/utils/analytics'
import { getBooleanFromLocalStorage, writeToLocalStorage } from '@/utils/local-storage'
import { logger } from '@/utils/logging'
import { useTranslate } from '@/utils/translate/translate-client'
import { LocaleWarningModalState } from '@/views/TicketCheckoutViews/ShowtimesView/components/VenueList'
import { SharingModal } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/SharingModal'
import { parseTheatricalDate, publishTicketIntent } from '@/views/TicketCheckoutViews/ShowtimesView/utils/utils'
import { getLanguageName } from '@/views/TicketCheckoutViews/utils'

interface TicketsContextValues {
  theatricalRelease: TheatricalReleaseObject
  query: ParsedUrlQuery
  routerIsReady: boolean
  openSharingModal: () => void
  closeSharingModal: () => void
  guildTicketsCount: number
  guildDiscountCodes: string[]
  isFreeTicketPromo: boolean
  discount: DiscountType | undefined
  trackTicketIntent: (analyticsPayload: TicketsAnalyticsPayload) => void
  detectLanguageDifference: (showTime: TheatricalShowtime) => boolean
  handleMobileShare: () => void
  shouldHideDiscountsForMarketing: boolean
  setPromoExperiment: React.Dispatch<React.SetStateAction<TheatricalPromoExperimentVariation | null>>
  promoExperiment: TheatricalPromoExperimentVariation | null
  promo: TheatricalPromoType | undefined
  handleLanguageWarning: (
    setLocaleWarningModalState: React.Dispatch<React.SetStateAction<LocaleWarningModalState>>,
    showTime: TheatricalShowtime,
    callback: () => void,
  ) => void
  adjustedDiscount: number | undefined | null
  venueRefinementExperiment?: boolean
  groupTicketExperiment?: boolean
}

export type DiscountType = { description: string; value: number; name?: string; code: string; category?: string }

const TicketsContext = React.createContext<TicketsContextValues | null>(null)

export type TicketsAnalyticsPayload = {
  venue: TheatricalShowtimeVenue
  latitude: number | undefined
  longitude: number | undefined
  theatricalName: string
  theatricalSlug: string
  projectSlug: string
}

interface TicketsContextProviderProps {
  theatricalRelease: TheatricalReleaseObject
  promo?: TheatricalPromoType
  reservation?: ReservationObject | undefined
  defaultSearchDate?: Date
  venueRefinementExperiment?: boolean
  groupTicketExperiment?: boolean
}

export const TicketsContextProvider: ReactFCC<TicketsContextProviderProps> = ({
  children,
  theatricalRelease,
  reservation,
  promo,
  venueRefinementExperiment,
  groupTicketExperiment = false,
}) => {
  const { asPath, query, isReady: routerIsReady } = useRouter()
  const [displaySharingModal, setDisplaySharingModal] = useState(false)
  const [promoExperiment, setPromoExperiment] = useState<TheatricalPromoExperimentVariation | null>(null)
  const { t } = useTranslate('tickets')
  const { showToast } = useToast()
  const { locale } = useLocale()
  const { track } = useSafeAnalytics()
  const isDatesDisabled = false
  const currentPathWithoutQueryParams = asPath.split('?')[0]
  const releaseDate = useMemo(() => {
    if (theatricalRelease?.region?.releaseDate) {
      return parseTheatricalDate(new Date(theatricalRelease?.region?.releaseDate).toISOString())
    }
    if (theatricalRelease?.releaseDate) return parseTheatricalDate(theatricalRelease.releaseDate)
    return null
  }, [theatricalRelease?.region?.releaseDate, theatricalRelease?.releaseDate])
  const isFreeTicketPromo = query?.promo === 'sof-claim-free' || query?.promo === 'claim-free-ticket'

  const closeSharingModal = useCallback(() => setDisplaySharingModal(false), [setDisplaySharingModal])

  const openSharingModal = useCallback(() => setDisplaySharingModal(true), [setDisplaySharingModal])
  const handleMobileShare = useCallback(async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: t('ticketsSEOTitle', `{{theatricalName}} Tickets & Showtimes | Angel Studios`, {
            theatricalName: theatricalRelease?.title,
          }),
          text: t(
            'ticketsSEODescription',
            'Buy {{theatricalName}} tickets, watch trailers and find showtimes near you.',
            {
              theatricalName: theatricalRelease?.title,
            },
          ),
          url: `${paths.base}${currentPathWithoutQueryParams}`,
        })
      } catch (error) {
        logger().error('An error occurred sharing ticket page on mobile', error)
      }
    }
  }, [currentPathWithoutQueryParams, t, theatricalRelease?.title])

  const setExperimentPromo = useCallback(() => {
    if (promo?.experimentDataCollection?.items && promo?.experimentDataCollection?.items?.length > 0) {
      const experimentName = promo.experimentDataCollection.items[0] ?? null
      setPromoExperiment(experimentName)
    }
  }, [promo, setPromoExperiment])

  useEffect(() => {
    setExperimentPromo()
  }, [setExperimentPromo])

  const shouldHideDiscountsForMarketing = query?.promoOverride === 'true'

  const { isGuildMember } = useGuildMember()
  const client = getWebClient()
  const { numberAvailable: guildTicketsCount = 0, discountCodes } = useUserGuildTickets({
    theatricalSlug: theatricalRelease.projectSlug,
    client,
    skip: !isGuildMember,
  })

  const guildDiscountCodes = useMemo(() => {
    return discountCodes?.map((theatricalDiscount) => theatricalDiscount?.code)
  }, [discountCodes])

  const detectLanguageDifference = useCallback(
    (showTime: TheatricalShowtime) => {
      const showTimeLanguage = showTime?.language
      const subtitleLanguage = showTime?.subtitleLanguage
      const wrongLanguage = showTimeLanguage !== locale && showTimeLanguage != null
      const wrongSubtitle = subtitleLanguage !== locale && subtitleLanguage != null

      return wrongLanguage || wrongSubtitle
    },
    [locale],
  )

  const getLanguageWarningMessage = useCallback(
    (showTimeLanguage: string, subtitleLanguage: string) => {
      const wrongLanguage = showTimeLanguage !== locale && showTimeLanguage != null
      const wrongSubtitle = subtitleLanguage !== locale && subtitleLanguage != null

      if (wrongLanguage && wrongSubtitle) {
        return t(
          'showtimeLanguageAndSubtitlesWarning',
          'The showtime you have selected is in {{language}} with {{subtitleLanguage}} subtitles',
          { language: getLanguageName(showTimeLanguage), subtitleLanguage: getLanguageName(subtitleLanguage) },
        )
      }

      if (wrongLanguage) {
        return t('showtimeLanguageWarning', 'The showtime you have selected is in {{language}}.', {
          language: getLanguageName(showTimeLanguage),
        })
      }

      if (wrongSubtitle) {
        return t('showtimeSubtitleWarning', 'The showtime you have selected has {{subtitleLanguage}} subtitles.', {
          subtitleLanguage: getLanguageName(subtitleLanguage),
        })
      }
    },
    [locale, t],
  )

  const handleLanguageWarning = useCallback(
    (
      setLocaleWarningModalState: React.Dispatch<React.SetStateAction<LocaleWarningModalState>>,
      showTime: TheatricalShowtime,
      callback: () => void,
    ) => {
      const languageWarningMessage = getLanguageWarningMessage(
        showTime?.language as string,
        showTime?.subtitleLanguage as string,
      )
      if (languageWarningMessage) {
        return setLocaleWarningModalState({
          isOpen: true,
          callback: () => {
            callback()
          },
          warning: languageWarningMessage,
        })
      }
    },
    [getLanguageWarningMessage],
  )

  const discount = useMemo(() => {
    const isPromoValid = isTheatricalPromoValid(promo)
    if (!isPromoValid) return

    const pendingDiscountName = promoExperiment?.pendingReservationDiscountName ?? promo?.pendingReservationDiscountName

    const pendingDiscount = reservation?.pendingDiscounts?.find((discount) => {
      return discount?.name?.toLowerCase() === pendingDiscountName?.toLowerCase()
    })

    if (pendingDiscount) {
      const percent = Math.floor(pendingDiscount?.value * 100)
      return {
        description: t('discountPercent', '{{percent}}%', { percent }),
        value: percent,
        name: pendingDiscount?.name,
        code: promo?.code as string,
      }
    }

    if (!pendingDiscount && promo?.discount) {
      const percent = Math.floor(promo?.discount * 100)
      return {
        description: t('discountPercent', '{{percent}}%', { percent }),
        value: percent,
        code: promo?.code as string,
      }
    }
  }, [promo, promoExperiment, reservation?.pendingDiscounts, t])

  const adjustedDiscount = useMemo(() => {
    if (promo?.type === TicketPromoTypes.EarlyBird) {
      const startDate = new Date(promo?.startDate)
      const currentDate = new Date()
      const daysSinceStart = Math.floor((currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))
      const newDiscount = Math.max(15, Number(discount?.value) - daysSinceStart)
      return isNaN(newDiscount) ? discount?.value : newDiscount
    }
    return discount?.value
  }, [promo, discount?.value])

  const trackTicketIntent = useCallback(
    (analyticsPayload: TicketsAnalyticsPayload) => {
      publishTicketIntent(analyticsPayload)
      track('Tickets Checkout Intent', analyticsPayload)
    },
    [track],
  )

  useEffect(() => {
    const shouldShowTicketUpdateShowtimeError = getBooleanFromLocalStorage('TICKET_SHOWTIME_SET_ERROR')
    if (shouldShowTicketUpdateShowtimeError) {
      showToast(t('couldntSelectShowtimeSelectAnother', 'We are unable to select that showtime, please try another!'))
      writeToLocalStorage('TICKET_SHOWTIME_SET_ERROR', false)
    }
  }, [showToast, t])

  const value = useMemo(() => {
    return {
      query,
      isDatesDisabled,
      isFreeTicketPromo,
      routerIsReady,
      releaseDate,
      handleMobileShare,
      openSharingModal,
      closeSharingModal,
      theatricalRelease,
      guildDiscountCodes,
      guildTicketsCount,
      discount,
      detectLanguageDifference,
      trackTicketIntent,
      shouldHideDiscountsForMarketing,
      handleLanguageWarning,
      setPromoExperiment,
      promoExperiment,
      promo,
      adjustedDiscount,
      venueRefinementExperiment,
      groupTicketExperiment,
    }
  }, [
    query,
    isDatesDisabled,
    isFreeTicketPromo,
    routerIsReady,
    releaseDate,
    handleMobileShare,
    openSharingModal,
    closeSharingModal,
    theatricalRelease,
    guildDiscountCodes,
    guildTicketsCount,
    discount,
    detectLanguageDifference,
    trackTicketIntent,
    shouldHideDiscountsForMarketing,
    handleLanguageWarning,
    promoExperiment,
    promo,
    adjustedDiscount,
    venueRefinementExperiment,
    groupTicketExperiment,
  ])

  return (
    <TicketsContext.Provider value={value}>
      {children}
      <SharingModal
        className="z-[80]"
        open={displaySharingModal}
        onClose={closeSharingModal}
        url={`${paths.base}${currentPathWithoutQueryParams}`}
      />
    </TicketsContext.Provider>
  )
}

export const useTicketsContext = (): TicketsContextValues => {
  return React.useContext(TicketsContext) as TicketsContextValues
}
